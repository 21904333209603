import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useDepositsList() {
  // Use toast
  const toast = useToast();

  const refDepositListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#", class: "text-center" },
    { key: "ref", label: i18n.t("Transaction id"), stickyColumn: true },
    { key: "username", label: i18n.t("Username"), stickyColumn: true },
    { key: "affiliate_id", label: i18n.t("Mã đại lý") },
    { key: "tags", label: i18n.t("Tags") },
    { key: "type", label: i18n.t("Type") },
    { key: "fullName", label: i18n.t("Full Name") },
    // {
    //   key: "companyBankAccountCode",
    //   label: i18n.t("Company bank account code"),
    // },
    // { key: "account_name", label: i18n.t("Member bank account name") },
    // { key: "account_number", label: i18n.t("Member bank account no") },
    // { key: "bank_ref", label: i18n.t("Member bank ref no") },
    // { key: "currency", label: i18n.t("Currency") },
    { key: "createdAt", label: i18n.t("Created at") },
    { key: "updatedAt", label: i18n.t("Updated at") },
    { key: "amount", label: `${i18n.t("Amount")} Gross` },
    { key: "fee", label: i18n.t("Charge amount") },
    { key: "netAmount", label: `${i18n.t("Amount")} Net` },
    { key: "status", label: i18n.t("Status") },
    { key: "action", stickyColumn: true, label: i18n.t("Actions"), class: "" },
  ];
  const perPage = ref(20);
  const totalDeposits = ref(0);
  const currentPage = ref(1);
  const depositsId =ref(1)
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const referenceFilter = ref(null);
  const usernameFilter = ref(null);
  const statusFilter = ref(null);
  const currencyFilter = ref(null);
  const fromdateFilter = ref("");
  const todateFilter = ref("");
  const bankFilter = ref(null);
  const bankAccountFilter = ref(null);
  const memberAccountNoFilter = ref(null);
  const accountNumberFilter = ref(null);
  const tagsFilter = ref(null);
  const referenceNoFilter = ref(null);
  // const bankOptions = ref(null);
  // const currencyOptions = ref(null);

  const refetchData = () => {
    refDepositListTable.value.refresh();
  };


  watch(
      [
        currentPage,
        // perPage
      ],
      () => {
        refetchData();
      }
  );
  // store.dispatch("local-setting-bank/fetchBanks", {}).then((response) => {
  //   bankOptions.value = response;
  // });

  const fetchDeposits = (ctx, callback) => {
    store
      .dispatch("payment-deposit/fetchDeposits", {
        page: currentPage.value,
        type: typeFilter.value,
        username: usernameFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value || null,
        todate: todateFilter.value || null,
        bankId: bankFilter.value,
        stat: statusFilter.value,
        tags: tagsFilter.value,
        bank_account_name: bankAccountFilter.value || null,
        account_number: accountNumberFilter.value,
        member_bank_ref: referenceNoFilter.value,
      })
      .then((response) => {
        const { deposits, total, pageSize } = response;
        callback(deposits);
        totalDeposits.value = total;
        perPage.value = pageSize;
      })
      .catch((error) => {
        const { response } = error;
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching deposits list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refDepositListTable.value
      ? refDepositListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDeposits.value,
    };
  });

  // const statusOptions = [
  //   { label: "Pending", value: 1 },
  //   { label: "Success", value: 2 },
  //   { label: "Reject/Fail", value: 3 },
  // ];
  // const statusOptions = ref([]);
  // store
  //   .dispatch("local-setting-currency/fetchOptionStatus")
  //   .then((response) => {
  //       console.log('currency')
  //     statusOptions.value = response.data.data.map((val) => {
  //       return {
  //         label: val.name,
  //         value: val.id,
  //       };
  //     });
  //   });
  // const typeOptions = ref([]);
  //
  // store.dispatch("local-setting-currency/fetchPaymentType",{
  //   id: depositsId.value
  // }).then((response) => {
  //   typeOptions.value = response.data?.data?.type_list.map((val) => {
  //     return {
  //       label: val.name,
  //       value: val.id,
  //     };
  //   });
  // });
  // store.dispatch("local-setting-currency/fetchCurrencies").then((response) => {
  //   if (response.data.code == "00") {
  //     currencyOptions.value = response.data.data;
  //   }
  // });

  // const tagsOptions = ref([]);
  //
  // store.dispatch("app-user-tag/fetchTagTypesFilter", {}).then((response) => {
  //   const { tagTypes } = response;
  //   tagsOptions.value = tagTypes;
  // });

  // const typeOptions = [
  //   { label: "Deposit", value: 1 },
  //   { label: "Local Deposit", value: 2 },
  //   { label: "Local Transfer", value: 3 },
  //   { label: "Momo Deposit", value: 4 },
  // ];

  const resolveType = (type) => {
    if (type === 1) return "Deposit";
    if (type === 2) return "Local deposit";
    if (type === 3) return "Local transfer";
    if (type === 4) return "Momo Deposit";
  };

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
  };

  return {
    fetchDeposits,
    tableColumns,
    perPage,
    currentPage,
    totalDeposits,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDepositListTable,

    resolveStatus,
    refetchData,
    resolveType,

    // Extra Filters
    typeFilter,
    referenceFilter,
    usernameFilter,
    statusFilter,
    currencyFilter,
    fromdateFilter,
    todateFilter,
    bankFilter,
    bankAccountFilter,
    memberAccountNoFilter,
    accountNumberFilter,
    tagsFilter,
    referenceNoFilter,

    // typeOptions,
    // statusOptions,
    // tagsOptions,
    // bankOptions,
    // currencyOptions,
  };
}
